import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Pagination } from 'react-bootstrap';
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import Header from '../Header/header';

const EventCalendar = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ name: '', description: '', venue: '', dateTime: '', endTime: '' });
    const [events, setEvents] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await fetch('https://isameapi.emedha.in/pages/api/api/events');
            const data = await response.json();
            if (Array.isArray(data)) {
                setEvents(data);
            } else {
                console.error('Expected an array but received:', data);
                setEvents([]);
            }
        } catch (error) {
            console.error('Error fetching events:', error);
            setEvents([]);
        }
    };

    const handleShow = (event = null) => {
        if (event) {
            setFormData({
                name: event.name,
                description: event.description,
                venue: event.venue,
                dateTime: event.datetime ? event.datetime.slice(0, 16) : '', // Ensuring only 'YYYY-MM-DDTHH:mm' format
                endTime: event.endTime ? event.endTime.slice(0, 16) : '' // Same for endTime
            });
            setEditingId(event.id);
        } else {
            setFormData({ name: '', venue: '', dateTime: '', endTime: '' });
            setEditingId(null);
        }
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const method = editingId ? 'PUT' : 'POST';
        const url = editingId
            ? `https://isameapi.emedha.in/pages/api/api/events/${editingId}`
            : 'https://isameapi.emedha.in/pages/api/api/events';

        // Only send fields that are populated
        const eventData = {};
        if (formData.name) eventData.name = formData.name;
        if (formData.description) eventData.description = formData.description;
        if (formData.venue) eventData.venue = formData.venue;
        if (formData.dateTime) eventData.dateTime = formData.dateTime;
        if (formData.endTime) eventData.endTime = formData.endTime;

        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(eventData),
        });

        if (response.ok) {
            fetchEvents(); // Refresh events list
            handleClose();
        } else {
            console.error('Error submitting event:', response.statusText);
        }
    };

    const handleDelete = async (eventId) => {
        if (window.confirm('Are you sure you want to delete this event?')) {
            const response = await fetch(`https://isameapi.emedha.in/pages/api/api/events/${eventId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                fetchEvents(); // Refresh events list
            } else {
                console.error('Error deleting event:', response.statusText);
            }
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const convertUTCToLocal = (utcDate) => {
        console.log('Converting UTC Date:', utcDate);

        const date = new Date(utcDate);
        if (isNaN(date)) {
            console.error('Invalid Date:', utcDate);
            return '';
        }

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,  // 24-hour format
        };

        return date.toLocaleString('en-GB', options);
    };


    // Pagination logic
    const totalPages = Math.ceil(events.length / itemsPerPage);
    const currentEvents = events.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 className="d-flex justify-content-between align-items-center mb-4">
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Event Calendar
                    <Button className="btn btn-primary btn-sm" onClick={() => handleShow()}>+ Add</Button>
                </h1>

                <Table striped bordered hover responsive="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Schedule</th>
                            <th>Event Particulars</th>
                            <th>Description</th>
                            <th>Proposed Venue / Remarks</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentEvents.map((event, index) => (
                            <tr key={event.id}>
                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{event.datetime ? convertUTCToLocal(event.datetime) : ''} - {event.endtime ? convertUTCToLocal(event.endtime) : ''}</td>
                                <td>{event.name}</td>
                                <td>{event.description || ''}</td>
                                <td>{event.venue || ''}</td>
                                <td>
                                    <Button onClick={() => handleShow(event)} className="btn btn-warning btn-sm me-4">Edit</Button>
                                    <MdDelete style={{ cursor: 'pointer' }} onClick={() => handleDelete(event.id)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* Modal for Adding/Editing Events */}
                <Modal show={showModal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{editingId ? 'Edit Event' : 'Add Event'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Event Particulars</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="venue" className="form-label">Proposed Venue / Remarks</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="venue"
                                    name="venue"
                                    value={formData.venue}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="dateTime" className="form-label">Schedule</label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    id="dateTime"
                                    name="dateTime"
                                    value={formData.dateTime}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="endTime" className="form-label">End Time</label>
                                <input
                                    type="datetime-local"
                                    className="form-control"
                                    id="endTime"
                                    name="endTime"
                                    value={formData.endTime}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <Button className="btn btn-primary btn-sm" type="submit">
                                Submit
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>

                {/* Pagination Controls */}
                <Pagination className="justify-content-center mt-4">
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map(page => (
                        <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                </Pagination>
            </div>
        </>
    );
};

export default EventCalendar;
