import React, { useEffect, useState } from 'react';
import { Modal, Button, Table, Pagination } from 'react-bootstrap';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header/header';

const Speakers = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ name: '', title: '', image: null });
    const [speakers, setSpeakers] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const fetchSpeakers = async () => {
            try {
                const response = await axios.get('https://isameapi.emedha.in/pages/api/api/speakers');
                setSpeakers(response.data);
            } catch (error) {
                console.error('Error fetching speakers:', error);
            }
        };

        fetchSpeakers();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const handleShow = (index = null) => {
        if (index !== null) {
            setFormData({ ...speakers[index], image: null });
            setEditIndex(index);
        } else {
            setFormData({ name: '', title: '', image: null });
            setEditIndex(null);
        }
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
      if (name === 'image') {
            setFormData({ ...formData, image: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, title, image } = formData;

        if (name.trim() && title.trim()) {
            const formDataToSend = new FormData();
            formDataToSend.append('name', name);
            formDataToSend.append('title', title);
            if (image) {
                formDataToSend.append('image', image);
            }

            try {
                if (editIndex !== null) {
                    await axios.put(`https://isameapi.emedha.in/pages/api/api/speakers/${speakers[editIndex].id}`, formDataToSend);
                    const updatedSpeakers = [...speakers];
                    updatedSpeakers[editIndex] = { ...formData, id: speakers[editIndex].id };
                    setSpeakers(updatedSpeakers);
                } else {
                    const response = await axios.post('https://isameapi.emedha.in/pages/api/api/speakers', formDataToSend);
                    setSpeakers([...speakers, response.data]);
                }
                setFormData({ name: '', title: '', image: '' });
                handleClose();
            } catch (error) {
                console.error('Error saving speaker:', error);
            }
        } else {
            console.error('Name and Title are required');
        }
    };


    const handleDelete = async (index) => {
        const speakerToDelete = speakers[index];
        if (window.confirm(`Are you sure you want to delete ${speakerToDelete.name}?`)) {
            try {
                await axios.delete(`https://isameapi.emedha.in/pages/api/api/speakers/${speakerToDelete.id}`);
                const newSpeakers = speakers.filter((_, i) => i !== index);
                setSpeakers(newSpeakers);
            } catch (error) {
                console.error('Error deleting speaker:', error);
            }
        }
    };

    // Pagination Logic
    const totalPages = Math.ceil(speakers.length / itemsPerPage);
    const currentSpeakers = speakers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Speakers & Sessions
                    <Button className="btn btn-primary btn-sm" onClick={() => handleShow()}>+ Add</Button>
                </h1>

                <div className="table-responsive">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name Of The Speaker</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentSpeakers.map((speaker, index) => (
                                <tr key={speaker.id}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{speaker.name}</td>
                                    <td>{speaker.title}</td>
                                    <td><img src={speaker.imageUrl} alt={speaker.name} style={{ width: '100px' }} /></td>
                                    <td>
                                        <Button variant="warning btn-sm me-4" onClick={() => handleShow((currentPage - 1) * itemsPerPage + index)}>Edit</Button>
                                        <Button variant="danger btn-sm" onClick={() => handleDelete((currentPage - 1) * itemsPerPage + index)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                <Modal show={showModal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{editIndex !== null ? 'Edit Speaker' : 'Add Speaker'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name Of The Speaker</label>
                                <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputChange}  />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">Title</label>
                                <textarea className="form-control" id="title" name="title" value={formData.title} onChange={handleInputChange}  />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Upload Image</label>
                                <input type="file" className="form-control" id="image" name="image" accept="image/*" onChange={handleInputChange}  />
                            </div>
                            <Button className="btn btn-primary btn-sm" type="submit">
                                Submit
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>

                <Pagination className="justify-content-center mt-4">
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map(page => (
                        <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                </Pagination>
            </div>
        </>
    );
};

export default Speakers;
