import React, { useEffect, useState } from 'react';
import { Modal, Table, Pagination } from 'react-bootstrap';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/header';

const MenuItem = () => {
    const [showModal, setShowModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState([]);
    const [editItemId, setEditItemId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Define items per page
    const navigate = useNavigate();

    const handleShow = () => setShowModal(true);
    const handleClose = () => {
        setShowModal(false);
        setInputValue('');
        setEditItemId(null);
    };

    const fetchItems = async () => {
        try {
            const response = await fetch('https://isameapi.emedha.in/pages/api/menuitems');
            const data = await response.json();
            setItems(data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (inputValue) {
            if (editItemId) {
                // Update existing item
                try {
                    const response = await fetch(`https://isameapi.emedha.in/pages/api/api/menuitems/${editItemId}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ name: inputValue }),
                    });

                    if (response.ok) {
                        const updatedItem = await response.json();
                        setItems(items.map(item => (item.id === editItemId ? updatedItem : item)));
                        handleClose();
                    } else {
                        console.error('Error updating item:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error updating item:', error);
                }
            } else {
                // Add new item
                try {
                    const response = await fetch('https://isameapi.emedha.in/pages/api/menuitems', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ name: inputValue }),
                    });

                    if (response.ok) {
                        const newItem = await response.json();
                        setItems([...items, newItem]);
                        handleClose();
                    } else {
                        console.error('Error adding item:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error adding item:', error);
                }
            }
        }
    };

    const handleEdit = (item) => {
        setInputValue(item.name);
        setEditItemId(item.id);
        handleShow();
    };

    const handleDelete = async (itemId) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                const response = await fetch(`https://isameapi.emedha.in/pages/api/api/menuitems/${itemId}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    setItems(items.filter(item => item.id !== itemId));
                } else {
                    console.error('Error deleting item:', response.statusText);
                }
            } catch (error) {
                console.error('Error deleting item:', error);
            }
        }
    };

    useEffect(() => {
        fetchItems();
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    // Pagination logic
    const totalPages = Math.ceil(items.length / itemsPerPage);
    const currentItems = items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Menu Items
                    <button className="btn btn-primary btn-sm" onClick={handleShow}>+ Add</button>
                </h1>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr key={item.id}>
                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                    <button className="btn btn-warning btn-sm me-4" onClick={() => handleEdit(item)}>Edit</button>
                                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(item.id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{editItemId ? 'Edit Menu Item' : 'Add Menu Item'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="itemInput" className="form-label">Item Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="itemInput"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    required
                                />
                            </div>
                            <button className="btn btn-primary btn-sm" type="submit">
                                Submit
                            </button>
                        </form>
                    </Modal.Body>
                </Modal>

                {/* Pagination controls */}
                <Pagination className="justify-content-center">
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map(page => (
                        <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                </Pagination>

            </div>
        </>
    );
};

export default MenuItem;
