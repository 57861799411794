import React, { useEffect, useState } from 'react';
import Header from '../Header/header';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Evaluation = () => {
    const [reviews, setReviews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 30; // Fixed items per page
    const navigate = useNavigate();

    useEffect(() => {
        fetchEvaluations();
    }, []);

    const fetchEvaluations = async () => {
        try {
            const response = await axios.get('https://isameapi.emedha.in/pages/api/speakersreview');
            console.log('Fetched reviews:', response.data); // Log the fetched data
            setReviews(response.data);
        } catch (error) {
            console.error('Error fetching evaluations:', error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    // Pagination logic
    const indexOfLastReview = currentPage * itemsPerPage;
    const indexOfFirstReview = indexOfLastReview - itemsPerPage;
    const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);
    const totalPages = Math.ceil(reviews.length / itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer', position: 'absolute', left: '100px' }} />
                    <span style={{ textAlign: 'center', flexGrow: 1 }}>Evaluation</span>
                </h1>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>About the Topic</th>
                            <th>Rating</th>
                            <th>Review</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentReviews.length > 0 ? (
                            currentReviews.map((review, index) => (
                                <tr key={review.id}>
                                    <td>{indexOfFirstReview + index + 1}</td>
                                    <td>{review.topic}</td>
                                    <td>
                                        {'★'.repeat(review.rating) + '☆'.repeat(5 - review.rating)}
                                    </td>
                                    <td>{review.review}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'center' }}>No reviews found.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* Pagination Controls */}
                <div className="d-flex justify-content-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            onClick={() => setCurrentPage(index + 1)}
                            className={`mx-1 ${currentPage === index + 1 ? 'active' : ''}`}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Evaluation;
