import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import A1 from '../images/isamelogo.png';


const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check credentials
    if (email === 'admin.isame@gmail.com' && password === 'admin@3214') {
      console.log('Login successful', { email, password });
      navigate('/dashboard-dashboard');
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div style={{ position: 'relative', width: '400px', height: '480px', backgroundColor: '#9eb7d7', border: '2px solid rgba(255, 255, 255, .5)', backdropFilter: 'blur(20px)', borderRadius: '20px', boxShadow: '0 0 30px rgba(0, 0, 0, .5)', margin: 'auto', marginTop: '150px', padding: '40px', overflow: 'hidden' }}>
        <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
          <img src={A1} alt="Logo" className="logo" style={{ height: '90px' }} />
          <h2 style={{ fontSize: '2em', color: '#000', textAlign: 'center' }}>Login</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div style={{ position: 'relative', width: '100%', height: '50px', borderBottom: '2px solid #000', margin: '30px 0' }}>
            <span className="icon"><i className='bx bx-envelope'></i></span>
            <input
              type="email"
              required
              style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', fontSize: '1em',  fontWeight: 600, padding: '0 35px 0 5px' }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Email</label>
          </div>
          <div style={{ position: 'relative', width: '100%', height: '50px', borderBottom: '2px solid #000', margin: '30px 0' }}>
            <span className="icon"><ion-icon name="lock-closed-outline"></ion-icon></span>
            <input
              type="password"
              required
              style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', fontSize: '1em',  fontWeight: 600, padding: '0 35px 0 5px' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label>Password</label>
          </div>
          <button type="submit" style={{ width: '100%', height: '45px', background: '#000', border: 'none', outline: 'none', borderRadius: '6px', cursor: 'pointer', fontSize: '1em', color: '#fff', fontWeight: 500 }}>Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
