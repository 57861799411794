import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';

const OnlinePoll = () => {
    const [show, setShow] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [polls, setPolls] = useState([]);
    const [newPoll, setNewPoll] = useState({ question: '', options: ['', '', ''] });
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const pollsPerPage = 10;

    useEffect(() => {
        fetchPolls();
    }, []);

    const fetchPolls = async () => {
        try {
            const response = await axios.get('https://isameapi.emedha.in/pages/api/api/polls');
            console.log('Fetched Poll Entries:', response.data);
            setPolls(response.data);
        } catch (error) {
            console.error('Error fetching polls:', error);
        }
    };

    const handleInputChange = (index, value) => {
        const options = [...newPoll.options];
        options[index] = value;
        setNewPoll({ ...newPoll, options });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataToSubmit = {
            question: newPoll.question,
            options: JSON.stringify(newPoll.options),
        };

        try {
            if (isEditing) {
                await updatePoll(dataToSubmit);
            } else {
                await createPoll(dataToSubmit);
            }
            fetchPolls();
            handleClose();
        } catch (error) {
            console.error('Error saving poll entry:', error);
        }
    };

    const createPoll = async (data) => {
        try {
            await axios.post('https://isameapi.emedha.in/pages/api/api/polls', data);
        } catch (error) {
            console.error('Error creating poll:', error);
        }
    };

    const updatePoll = async (data) => {
        try {
            await axios.put(`https://isameapi.emedha.in/pages/api/api/polls/${editId}`, data);
        } catch (error) {
            console.error('Error updating poll:', error);
        }
    };

    const handleEdit = (index) => {
        const entry = polls[index];
        setNewPoll({ 
            question: entry.question, 
            options: Array.isArray(JSON.parse(entry.options)) ? JSON.parse(entry.options) : ['', '', ''] 
        });
        setEditId(entry.id);
        setIsEditing(true);
        setShow(true);
    };

    const resetForm = () => {
        setNewPoll({ question: '', options: ['', '', ''] });
        setIsEditing(false);
        setEditId(null);
    };

    const handleDelete = async (index) => {
        try {
            await axios.delete(`https://isameapi.emedha.in/pages/api/api/polls/${polls[index].id}`);
            fetchPolls(); // Refetch polls after deletion
        } catch (error) {
            console.error('Error deleting poll:', error);
        }
    };

    const handleClose = () => {
        setShow(false);
        resetForm();
    };

    // Pagination Logic
    const indexOfLastPoll = currentPage * pollsPerPage;
    const indexOfFirstPoll = indexOfLastPoll - pollsPerPage;
    const currentPolls = polls.slice(indexOfFirstPoll, indexOfLastPoll);
    const totalPages = Math.ceil(polls.length / pollsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 className="d-flex justify-content-between align-items-center mb-4">
                    <IoArrowBackCircle onClick={() => window.history.back()} style={{ cursor: 'pointer' }} />
                    Online Poll
                    <Button className="btn btn-primary btn-sm" onClick={() => { resetForm(); setShow(true); }}>Add</Button>
                </h1>

                <div className="table-responsive">
                    <table className="table mt-5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Poll Question</th>
                                <th>Options</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPolls.map((poll, index) => (
                                <tr key={poll.id}>
                                    <td>{index + 1 + indexOfFirstPoll}</td>
                                    <td>{poll.question}</td>
                                    <td>{JSON.parse(poll.options).join(', ')}</td>
                                    <td>
                                        <Button variant="warning btn-sm me-2" onClick={() => handleEdit(index + indexOfFirstPoll)}>Edit</Button>
                                        <Button variant="danger btn-sm" onClick={() => handleDelete(index + indexOfFirstPoll)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <div className="d-flex justify-content-between">
                    <Button 
                        variant="secondary" 
                        disabled={currentPage === 1} 
                        onClick={() => setCurrentPage(currentPage - 1)}
                    >
                        Previous
                    </Button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <Button 
                        variant="secondary" 
                        disabled={currentPage === totalPages} 
                        onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        Next
                    </Button>
                </div>
            </div>

            {/* Modal for Adding/Editing Poll */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Edit Poll' : 'Add Poll'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formPollQuestion">
                            <Form.Label>Poll Question</Form.Label>
                            <Form.Control
                                type="text"
                                value={newPoll.question}
                                onChange={(e) => setNewPoll({ ...newPoll, question: e.target.value })}
                                required
                            />
                        </Form.Group>
                        {newPoll.options.map((option, index) => (
                            <Form.Group key={index} controlId={`formOption${index}`}>
                                <Form.Label>Option {index + 1}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={option}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    required
                                />
                            </Form.Group>
                        ))}
                        <Button variant="primary btn-sm mt-3" type="submit">{isEditing ? 'Update' : 'Submit'}</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OnlinePoll;
