import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Header from '../Header/header';

const Helpdesk = () => {
  const [show, setShow] = useState(false);
  const [helpdeskEntries, setHelpdeskEntries] = useState([]);
  const [formData, setFormData] = useState({ description: '', call: '', whatsapp: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;

  useEffect(() => {
    fetchHelpdeskEntries();
  }, []);

  const fetchHelpdeskEntries = async () => {
    try {
      const response = await axios.get('https://isameapi.emedha.in/pages/api/api/helpdesk');
      setHelpdeskEntries(response.data);
    } catch (error) {
      console.error('Error fetching help desk entries:', error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
    setFormData({ description: '', call: '', whatsapp: '' });
  };

  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axios.put(`https://isameapi.emedha.in/pages/api/api/helpdesk/${editId}`, formData);
      } else {
        await axios.post('https://isameapi.emedha.in/pages/api/api/helpdesk', formData);
      }
      fetchHelpdeskEntries(); // Refresh the list
      handleClose();
    } catch (error) {
      console.error('Error saving help desk entry:', error);
    }
  };

  const handleEdit = (entry) => {
    setFormData(entry);
    setEditId(entry.id);
    setIsEditing(true);
    setShow(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://isameapi.emedha.in/pages/api/api/helpdesk/${id}`);
      fetchHelpdeskEntries(); // Refresh the list
    } catch (error) {
      console.error('Error deleting help desk entry:', error);
    }
  };

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = helpdeskEntries.slice(indexOfFirstEntry, indexOfLastEntry);
  
  const totalPages = Math.ceil(helpdeskEntries.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Header />
      <div className="container mt-5">
        <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <IoArrowBackCircle onClick={() => window.history.back()} style={{ cursor: 'pointer' }} />
          Help Desk
          <Button className="btn btn-primary btn-sm" onClick={handleShow}>+ Add</Button>
        </h1>

        {/* Modal for adding/editing help desk entries */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Edit Help Desk Entry' : 'Add Help Desk Entry'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" name="description" value={formData.description} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="formCall">
                <Form.Label>Call</Form.Label>
                <Form.Control type="text" name="call" value={formData.call} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="formWhatsapp">
                <Form.Label>WhatsApp</Form.Label>
                <Form.Control type="text" name="whatsapp" value={formData.whatsapp} onChange={handleChange} required />
              </Form.Group>
              <Button variant="btn btn-primary btn-sm mt-3" type="submit">
                {isEditing ? 'Update' : 'Submit'}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Table to display help desk entries */}
        <table className="table mt-5">
          <thead>
            <tr>
              <th>Description</th>
              <th>Call</th>
              <th>WhatsApp</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((entry) => (
              <tr key={entry.id}>
                <td>{entry.description}</td>
                <td>{entry.call}</td>
                <td>{entry.whatsapp}</td>
                <td>
                  <Button variant="btn btn-warning btn-sm me-2" onClick={() => handleEdit(entry)}>Edit</Button>
                  <Button variant="btn btn-danger btn-sm" onClick={() => handleDelete(entry.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between mt-3">
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          <div>
            Page {currentPage} of {totalPages}
          </div>
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default Helpdesk;
