import React, { useEffect, useState } from 'react';
import Header from '../Header/header';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Modal, Button, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const VenueLayout = () => {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ image: null, description: '' });
    const [venues, setVenues] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const navigate = useNavigate();

    const handleShow = () => setShowModal(true);
    const handleClose = () => {
        setShowModal(false);
        setFormData({ image: null, description: '' });
        setEditingIndex(null);
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'image') {
            setFormData({ ...formData, image: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    // Fetch all venues when the component mounts
    useEffect(() => {
        const fetchVenues = async () => {
            try {
                const response = await axios.get('https://isameapi.emedha.in/pages/api/api/venues');
                setVenues(response.data);
            } catch (error) {
                console.error('Error fetching venues:', error);
            }
        };
        fetchVenues();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('description', formData.description);
        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }

        try {
            if (editingIndex !== null) {
                // Update existing venue
                const venueId = venues[editingIndex].id; //  https://isameapi.emedha.in
                await axios.put(`https://isameapi.emedha.in/pages/api/api/venues/${venueId}`, formDataToSend);
            } else {
                // Add new venue
                await axios.post('https://isameapi.emedha.in/pages/api/api/venues', formDataToSend);
            }
            // Refresh the venue list
            const response = await axios.get('https://isameapi.emedha.in/pages/api/api/venues');
            setVenues(response.data);
            handleClose();
        } catch (error) {
            console.error('Error saving venue:', error);
        }
    };

    const handleEdit = (index) => {
        setFormData(venues[index]);
        setEditingIndex(index);
        handleShow();
    };

    const handleDelete = async (index) => {
        const venueId = venues[index].id;
        try {
            await axios.delete(`https://isameapi.emedha.in/pages/api/api/venues/${venueId}`);
            const updatedVenues = venues.filter((_, i) => i !== index);
            setVenues(updatedVenues);
        } catch (error) {
            console.error('Error deleting venue:', error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Venue
                    <Button className="btn btn-primary btn-sm" onClick={handleShow}>+ Add</Button>
                </h1>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {venues.map((venue, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {venue.image && (
                                        <img
                                            src={venue.image}
                                            alt="Venue"
                                            style={{ width: '50px', height: '50px' }}
                                        />
                                    )}
                                </td>
                                <td>{venue.description}</td>
                                <td>
                                    <Button onClick={() => handleEdit(index)} className="btn btn-warning btn-sm me-2">Edit</Button>
                                    <Button onClick={() => handleDelete(index)} className="btn btn-danger btn-sm">Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* Modal for Adding/Editing Venues */}
                <Modal show={showModal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{editingIndex !== null ? 'Edit Venue' : 'Add Venue'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Image</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    name="image"
                                    accept="image/*"
                                    onChange={handleInputChange}
                                 
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    
                                />
                            </div>
                            <Button className="btn btn-primary btn-sm" type="submit">
                                Submit
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default VenueLayout;

