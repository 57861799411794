import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header/header';

const Stalls = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [stallsData, setStallsData] = useState([]);
    const [formData, setFormData] = useState({
        stallno: '',
        stallowner: '',
        email: '',
        mobile: '',
        whatsappno: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 10;

    useEffect(() => {
        fetchStalls();
    }, []);

    const fetchStalls = async () => {
        try {
            const response = await axios.get('https://isameapi.emedha.in/pages/api/api/stalls');
            setStallsData(response.data);
        } catch (error) {
            console.error('Error fetching stalls:', error);
        }
    };

    const handleClose = () => setShow(false);

    const handleShow = () => {
        setShow(true);
        setIsEditing(false);
        setFormData({
            stallno: '',
            stallowner: '',
            email: '',
            mobile: '',
            whatsappno: ''
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing) {
                await axios.put(`https://isameapi.emedha.in/pages/api/api/stalls/${editId}`, formData);
            } else {
                await axios.post('https://isameapi.emedha.in/pages/api/api/stalls', formData);
            }
            fetchStalls();
            handleClose();
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const handleEdit = (stall) => {
        setFormData(stall);
        setEditId(stall.id);
        setIsEditing(true);
        setShow(true);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://isameapi.emedha.in/pages/api/api/stalls/${id}`);
            fetchStalls();
        } catch (error) {
            console.error('Error deleting stall:', error);
        }
    };

    // Pagination logic
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = stallsData.slice(indexOfFirstEntry, indexOfLastEntry);
    
    const totalPages = Math.ceil(stallsData.length / entriesPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 className="d-flex justify-content-between align-items-center mb-4">
                    <IoArrowBackCircle onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} />
                    Stalls
                    <Button className="btn btn-primary btn-sm" onClick={handleShow}>+ Add</Button>
                </h1>

                {/* Modal for adding/editing stalls */}
                <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? 'Edit Stall' : 'Add Stall'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formStallno">
                                <Form.Label>Stall No</Form.Label>
                                <Form.Control type="text" name="stallno" value={formData.stallno} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formStallowner">
                                <Form.Label>Stall Owner</Form.Label>
                                <Form.Control type="text" name="stallowner" value={formData.stallowner} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formMobile">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control type="text" name="mobile" value={formData.mobile} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formWhatsappno">
                                <Form.Label>WhatsApp No</Form.Label>
                                <Form.Control type="text" name="whatsappno" value={formData.whatsappno} onChange={handleChange} required />
                            </Form.Group>
                            <Button variant="primary btn-sm mt-3" type="submit">
                                {isEditing ? 'Update' : 'Submit'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Table to display stalls data */}
                <div className="table-responsive">
                    <table className="table mt-5">
                        <thead>
                            <tr>
                                <th>Stall No</th>
                                <th>Stall Owner</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>WhatsApp No</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEntries.map((stall) => (
                                <tr key={stall.id}>
                                    <td>{stall.stallno}</td>
                                    <td>{stall.stallowner}</td>
                                    <td>{stall.email}</td>
                                    <td>{stall.mobile}</td>
                                    <td>{stall.whatsappno}</td>
                                    <td>
                                        <Button variant="warning btn-sm me-2" onClick={() => handleEdit(stall)}>Edit</Button>
                                        <Button variant="danger btn-sm" onClick={() => handleDelete(stall.id)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <div className="d-flex justify-content-between mt-3">
                    <Button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        Previous
                    </Button>
                    <div>
                        Page {currentPage} of {totalPages}
                    </div>
                    <Button
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Stalls;
