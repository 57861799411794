// import React, { useState, useEffect } from 'react';
// import { IoArrowBackCircle } from 'react-icons/io5';
// import { Modal, Button, Form } from 'react-bootstrap';
// import axios from 'axios';
// import Header from '../Header/header';

// const OnlinePoll = () => {
//     const [show, setShow] = useState(false);
//     const [pollEntries, setPollEntries] = useState([]);
//     const [formData, setFormData] = useState({ topic: '', question: '', result: '', answers: '' });
//     const [isEditing, setIsEditing] = useState(false);
//     const [editId, setEditId] = useState(null);

//     // Pagination state
//     const [currentPage, setCurrentPage] = useState(1);
//     const entriesPerPage = 10;

//     useEffect(() => {
//         fetchPollEntries();
//     }, []);

//     const fetchPollEntries = async () => {
//         try {
//             const response = await axios.get('https://isameapi.emedha.in/pages/api/api/quiz');
//             setPollEntries(response.data);
//         } catch (error) {
//             console.error('Error fetching poll entries:', error);
//         }
//     };

//     const handleClose = () => {
//         setShow(false);
//         setIsEditing(false);
//         setFormData({ topic: '', question: '', result: '', answers: '' });
//     };

//     const handleShow = () => {
//         setShow(true);
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             if (isEditing) {
//                 await axios.put(`https://isameapi.emedha.in/pages/api/api/quiz/${editId}`, formData);
//             } else {
//                 await axios.post('https://isameapi.emedha.in/pages/api/api/quiz', formData);
//             }
//             fetchPollEntries();
//             handleClose();
//         } catch (error) {
//             console.error('Error saving poll entry:', error);
//         }
//     };

//     const handleEdit = (entry) => {
//         setFormData(entry);
//         setEditId(entry.id);
//         setIsEditing(true);
//         setShow(true);
//     };

//     const handleDelete = async (id) => {
//         try {
//             await axios.delete(`https://isameapi.emedha.in/pages/api/api/quiz/${id}`);
//             fetchPollEntries();
//         } catch (error) {
//             console.error('Error deleting poll entry:', error);
//         }
//     };

//     // Pagination logic
//     const indexOfLastEntry = currentPage * entriesPerPage;
//     const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
//     const currentEntries = pollEntries.slice(indexOfFirstEntry, indexOfLastEntry);

//     const totalPages = Math.ceil(pollEntries.length / entriesPerPage);

//     const handlePageChange = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     return (
//         <>
//             <Header />
//             <div className="container mt-5">
//                 <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
//                     <IoArrowBackCircle onClick={() => window.history.back()} style={{ cursor: 'pointer' }} />
//                     Online Poll
//                     <Button className="btn btn-primary btn-sm" onClick={handleShow}>+ Add</Button>
//                 </h1>

//                 <Modal show={show} onHide={handleClose}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>{isEditing ? 'Edit Poll Entry' : 'Add Poll Entry'}</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <Form onSubmit={handleSubmit}>
//                             <Form.Group controlId="formTopic">
//                                 <Form.Label>Topic</Form.Label>
//                                 <Form.Control type="text" name="topic" value={formData.topic} onChange={handleChange} required />
//                             </Form.Group>
//                             <Form.Group controlId="formQuestion">
//                                 <Form.Label>Poll Question</Form.Label>
//                                 <Form.Control type="text" name="question" value={formData.question} onChange={handleChange} required />
//                             </Form.Group>
//                             <Form.Group controlId="formResult">
//                                 <Form.Label>Result</Form.Label>
//                                 <Form.Control type="text" name="result" value={formData.result} onChange={handleChange} required />
//                             </Form.Group>
//                             <Form.Group controlId="formAnswers">
//                                 <Form.Label>Answers</Form.Label>
//                                 <Form.Control type="text" name="answers" value={formData.answers} onChange={handleChange} required />
//                                 <Form.Text className="text-muted">
//                                     Separate answers with commas.
//                                 </Form.Text>
//                             </Form.Group>
//                             <Button variant="btn btn-primary btn-sm mt-3" type="submit">
//                                 {isEditing ? 'Update' : 'Submit'}
//                             </Button>
//                         </Form>
//                     </Modal.Body>
//                 </Modal>

//                 <table className="table mt-5">
//                     <thead>
//                         <tr>
//                             <th>Topic</th>
//                             <th>Poll Question</th>
//                             <th>Answers</th>
//                             <th>Result</th>
//                             <th>Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {currentEntries.map((entry) => (
//                             <tr key={entry.id}>
//                                 <td>{entry.topic}</td>
//                                 <td>{entry.question}</td>
//                                 <td>{entry.answers}</td>
//                                 <td>{entry.result}</td>
//                                 <td>
//                                     <Button variant="btn btn-warning btn-sm me-2" onClick={() => handleEdit(entry)}>Edit</Button>
//                                     <Button variant="btn btn-danger btn-sm" onClick={() => handleDelete(entry.id)}>Delete</Button>
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>

//                 {/* Pagination Controls */}
//                 <div className="d-flex justify-content-between mt-3">
//                     <Button
//                         disabled={currentPage === 1}
//                         onClick={() => handlePageChange(currentPage - 1)}
//                     >
//                         Previous
//                     </Button>
//                     <div>
//                         Page {currentPage} of {totalPages}
//                     </div>
//                     <Button
//                         disabled={currentPage === totalPages}
//                         onClick={() => handlePageChange(currentPage + 1)}
//                     >
//                         Next
//                     </Button>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default OnlinePoll;


import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Modal, Button, Form, Pagination } from 'react-bootstrap';
import axios from 'axios';
import Header from '../Header/header';

const OnlinePoll = () => {
    const [show, setShow] = useState(false);
    const [pollEntries, setPollEntries] = useState([]);
    const [formData, setFormData] = useState({ topic: '', question: '' });
    const [options, setOptions] = useState(['', '', '', '']);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        fetchPollEntries();
    }, []);

    const fetchPollEntries = async () => {
        try {
            const response = await axios.get('https://isameapi.emedha.in/pages/api/api/quiz');
            console.log('Fetched Poll Entries:', response.data);
            setPollEntries(response.data);
        } catch (error) {
            console.error('Error fetching poll entries:', error);
        }
    };

    const handleClose = () => {
        setShow(false);
        setIsEditing(false);
        setFormData({ topic: '', question: '' });
        setOptions(['', '', '', '']);
        setCorrectAnswer('');
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const handleCorrectAnswerChange = (e) => {
        setCorrectAnswer(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataToSubmit = {
            ...formData,
            options: JSON.stringify(options),
            correctAnswer
        };

        try {
            if (isEditing) {
                await axios.put(`https://isameapi.emedha.in/pages/api/api/quiz/${editId}`, dataToSubmit);
            } else {
                await axios.post('https://isameapi.emedha.in/pages/api/api/quiz', dataToSubmit);
            }
            fetchPollEntries();
            handleClose();
        } catch (error) {
            console.error('Error saving poll entry:', error);
        }
    };

    const handleEdit = (entry) => {
        setFormData({ topic: entry.topic, question: entry.question });

        try {
            const parsedOptions = JSON.parse(entry.options);
            setOptions(parsedOptions);
        } catch (error) {
            console.error('Error parsing options:', error, 'Options:', entry.options);
            setOptions(['', '', '', '']);
        }

        setCorrectAnswer(entry.correctAnswer);
        setEditId(entry.id);
        setIsEditing(true);
        setShow(true);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://isameapi.emedha.in/pages/api/api/quiz/${id}`);
            fetchPollEntries();
        } catch (error) {
            console.error('Error deleting poll entry:', error);
        }
    };

    // Calculate current entries for pagination
    const indexOfLastEntry = currentPage * itemsPerPage;
    const indexOfFirstEntry = indexOfLastEntry - itemsPerPage;
    const currentEntries = pollEntries.slice(indexOfFirstEntry, indexOfLastEntry);

    // Calculate total pages
    const totalPages = Math.ceil(pollEntries.length / itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 className="d-flex justify-content-between align-items-center mb-4">
                    <IoArrowBackCircle onClick={() => window.history.back()} style={{ cursor: 'pointer' }} />
                    Online Quiz
                    <Button className="btn btn-primary btn-sm" onClick={handleShow}>+ Add</Button>
                </h1>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? 'Edit Poll Entry' : 'Add Poll Entry'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formTopic">
                                <Form.Label>Topic</Form.Label>
                                <Form.Control type="text" name="topic" value={formData.topic} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formQuestion">
                                <Form.Label>Poll Question</Form.Label>
                                <Form.Control type="text" name="question" value={formData.question} onChange={handleChange} required />
                            </Form.Group>
                            {options.map((option, index) => (
                                <Form.Group key={index} controlId={`formOption${index}`}>
                                    <Form.Label>Option {index + 1}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={option}
                                        onChange={(e) => handleOptionChange(index, e.target.value)}
                                        required
                                    />
                                </Form.Group>
                            ))}
                            <Form.Group controlId="formCorrectAnswer">
                                <Form.Label>Correct Answer</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={correctAnswer}
                                    onChange={handleCorrectAnswerChange}
                                    required
                                />
                            </Form.Group>
                            <Button variant="btn btn-primary btn-sm mt-3" type="submit">
                                {isEditing ? 'Update' : 'Submit'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Make table scrollable on small screens */}
                <div className="table-responsive">
                    <table className="table mt-5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Topic</th>
                                <th>Poll Question</th>
                                <th>Options</th>
                                <th>Answer</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEntries.map((entry, index) => (
                                <tr key={entry.id}>
                                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                    <td>{entry.topic}</td>
                                    <td>{entry.question}</td>
                                    <td>
                                        {(() => {
                                            try {
                                                const parsedOptions = JSON.parse(entry.options);
                                                if (Array.isArray(parsedOptions)) {
                                                    return parsedOptions.join(', ');
                                                }
                                            } catch (error) {
                                                console.error('Error parsing options:', error);
                                            }
                                            return entry.options || 'No options available';
                                        })()}
                                    </td>
                                    <td>{entry.correctAnswer}</td>
                                    <td>
                                        <Button variant="btn btn-warning btn-sm me-2" onClick={() => handleEdit(entry)}>Edit</Button>
                                        <Button variant="btn btn-danger btn-sm" onClick={() => handleDelete(entry.id)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <Pagination className="justify-content-center mt-4">
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map(page => (
                        <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
                </Pagination>
            </div>
        </>
    );
};

export default OnlinePoll;



 