import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/header';

const Dashboard = () => {
    return (
        <>
            <Header />
            <div className="container mt-5">
                <div style={{ padding: '20px' }}>

                    <div className='mt-5 d-flex flex-wrap justify-content-center'>
                        {/* <Link to="/images-upload-sessions" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Images Upload</button>
                        </Link> */}
                        <Link to="/menu-items" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Menu Items</button>
                        </Link>
                        <Link to="/event-calendar" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Event Calendar</button>
                        </Link>
                        <Link to="/speakers-sessions" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Speakers & Sessions</button>
                        </Link>
                        <Link to="/program" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Program</button>
                        </Link>
                        <Link to="/networking" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Networking</button>
                        </Link>
                        <Link to="/venue-layout" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Venue Layout</button>
                        </Link>
                        <Link to="/stall" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Stalls</button>
                        </Link>
                        <Link to="/evaluation" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Evaluation</button>
                        </Link>
                        <Link to="/online-quiz" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Online Quiz</button>
                        </Link>
                        <Link to="/notifications" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Notifications</button>
                        </Link>
                        <Link to="/help-desk" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Help Desk</button>
                        </Link>
                        <Link to="/attendance" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Attendees</button>
                        </Link>
                        <Link to="/online-poll" className="w-100 me-3 mb-3" style={{ maxWidth: '200px' }}>
                            <button style={{ backgroundColor: '#2d3a93', color: '#fff', height: '60px', borderRadius: '5px', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', }} className="btn btn-primary btn-sm w-100">Online Poll</button>
                        </Link>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Dashboard;
