import React, { useEffect, useState } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/header';
import axios from 'axios';
import { Pagination } from 'react-bootstrap'; // Import Pagination from React-Bootstrap

const Networking = () => {
  const navigate = useNavigate();
  const [attendees, setAttendees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);  // State for current page
  const [itemsPerPage] = useState(10);  // Number of items per page (you can adjust this)
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchAttendees = async () => {
      try {
        const response = await axios.get('https://isameapi.emedha.in/pages/api/attendees');
        setAttendees(response.data);

        // Calculate the total pages based on the number of attendees and items per page
        setTotalPages(Math.ceil(response.data.length / itemsPerPage));
      } catch (err) {
        console.error('Error fetching attendees:', err.message);
      }
    };

    fetchAttendees();
  }, [itemsPerPage]); // This hook runs once when the component mounts

  // Filter the attendees based on the search query
  const filteredAttendees = attendees.filter(attendee => {
    return (
      attendee.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      attendee.companyName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      attendee.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      attendee.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      attendee.district?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      attendee.productsServices?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      attendee.interestedInLBN?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      attendee.investment?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      attendee.globalNetworking?.toString().includes(searchQuery) // Handle non-string fields
    );
  });

  // Calculate the total pages for the filtered attendees
  const totalFilteredPages = Math.ceil(filteredAttendees.length / itemsPerPage);

  // Get the attendees for the current page based on the filtered list
  const currentAttendees = filteredAttendees.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Header />
      <div className="container mt-5">
        <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
          Networking
          <div>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </h1>

        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Profile</th>
              <th>Company</th>
              <th>City</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>District</th>
              <th>ProductsServices</th>
              <th>InterestedInLBN</th>
              <th>Investment</th>
              <th>GlobalNetworking</th>
            </tr>
          </thead>
          <tbody>
            {currentAttendees.length > 0 ? (
              currentAttendees.map((attendee, index) => (
                <tr key={attendee.id}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>{attendee.name}</td>
                  <td>
                    <img
                      src={attendee.profilePic || 'default-profile-pic.jpg'}
                      alt={`${attendee.name}'s Profile`}
                      style={{ width: 50, height: 50, borderRadius: '50%' }}
                    />
                  </td>
                  <td>{attendee.companyName}</td>
                  <td>{attendee.city}</td>
                  <td>{attendee.mobile}</td>
                  <td>{attendee.email}</td>
                  <td>{attendee.district}</td>
                  <td>{attendee.productsServices}</td>
                  <td>{attendee.interestedInLBN}</td>
                  <td>{attendee.investment}</td>
                  <td>{attendee.globalNetworking}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">No attendees found</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <Pagination className="justify-content-center">
          <Pagination.Prev
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          />
          {[...Array(totalFilteredPages)].map((_, pageIndex) => (
            <Pagination.Item
              key={pageIndex + 1}
              active={pageIndex + 1 === currentPage}
              onClick={() => setCurrentPage(pageIndex + 1)}
            >
              {pageIndex + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalFilteredPages))}
            disabled={currentPage === totalFilteredPages}
          />
        </Pagination>
      </div>
    </>
  );
};

export default Networking;
