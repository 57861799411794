import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './App.css';
import Dashboard from './Components/Pages/dashboard';
import Menuitem from './Components/Pages/menuitem';
import Evenntcalendar from './Components/Pages/eventcalendar';
import Speakers from './Components/Pages/speakers';
import Imagesupload from './Components/Pages/imagesupload';
import Program from './Components/Pages/program';
import Login from './Components/Auth/login';
import Stalls from './Components/Pages/stalls';
import Notifications from './Components/Pages/notifications';
import Helpdesk from './Components/Pages/helpdesk';
import Onlinequiz from './Components/Pages/onlinequiz';
import Header from './Components/Header/header';
import Attendance from './Components/Pages/attendance';
import VenueLayout from './Components/Pages/venuelayout';
import Eveluation from './Components/Pages/eveluation';
import OnlinePoll from './Components/Pages/onlinepoll';
import Networking from './Components/Pages/networking';

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/dashboard-dashboard' element={<Dashboard />} />
        <Route path='/' element={<Login />} />
        <Route path='/header' element={<Header />} />
        <Route path='/menu-items' element={<Menuitem />} />
        <Route path='/event-calendar' element={<Evenntcalendar />} />
        <Route path='/speakers-sessions' element={<Speakers />} />
        <Route path='/images-upload-sessions' element={<Imagesupload />} />
        <Route path='/program' element={<Program />} />
        <Route path='/stall' element={<Stalls />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path='/networking' element={<Networking />} />
        <Route path='/help-desk' element={<Helpdesk />} />
        <Route path='/online-quiz' element={<Onlinequiz />} />
        <Route path='/attendance' element={<Attendance />} />
        <Route path='/venue-layout' element={<VenueLayout />} />
        <Route path='/evaluation' element={<Eveluation />} />
        <Route path='/online-poll' element={<OnlinePoll />} />
      </Routes>
    </Router>
  );
}

export default App;
