import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Header from '../Header/header';
import { FaShirtsinbulk } from 'react-icons/fa';

const Attendance = () => {
    const [show, setShow] = useState(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [attendanceEntries, setAttendanceEntries] = useState([]);
    const [formData, setFormData] = useState({ name: '', email: '', mobile: '', district: '', registration: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [file, setFile] = useState(null);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const entriesPerPage = 10;

    useEffect(() => {
        fetchAttendanceEntries();
    }, []);

    const fetchAttendanceEntries = async () => {
        try {
            const response = await axios.get('https://isameapi.emedha.in/pages/api/api/attendance');
            setAttendanceEntries(response.data);
        } catch (error) {
            console.error('Error fetching attendance entries:', error);
        }
    };

    const handleClose = () => {
        setShow(false);
        setShowBulkUpload(false);
        setIsEditing(false);
        setFormData({ name: '', email: '', mobile: '', district: '', registration: '' });
    };

    const handleShow = () => setShow(true);
    const handleShowBulkUpload = () => setShowBulkUpload(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing) {
                await axios.put(`https://isameapi.emedha.in/pages/api/api/attendance/${editId}`, formData);
            } else {
                await axios.post('https://isameapi.emedha.in/pages/api/api/attendance', formData);
            }
            fetchAttendanceEntries();
            handleClose();
        } catch (error) {
            console.error('Error saving attendance entry:', error);
            if (error.response && error.response.data.error === 'Registration number must be unique.') {
                alert('The registration number must be unique. Please enter a different registration number.');
            } else {
                alert('An error occurred while saving the attendance entry.');
            }
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log('Selected file:', selectedFile);
        setFile(selectedFile);
    };

    const handleBulkUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);

        try {
            await axios.post('https://isameapi.emedha.in/pages/api/api/bulkupload', formData);
            fetchAttendanceEntries();
            handleClose();
        } catch (error) {
            console.error('Error uploading bulk attendance:', error.response ? error.response.data : error.message);
        }
    };

    const handleEdit = (entry) => {
        setFormData(entry);
        setEditId(entry.id);
        setIsEditing(true);
        setShow(true);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://isameapi.emedha.in/pages/api/api/attendance/${id}`);
            fetchAttendanceEntries();
        } catch (error) {
            console.error('Error deleting attendance entry:', error);
        }
    };

    // Pagination logic
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const currentEntries = attendanceEntries.slice(indexOfFirstEntry, indexOfLastEntry);

    const totalPages = Math.ceil(attendanceEntries.length / entriesPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={() => window.history.back()} style={{ cursor: 'pointer' }} />
                    Attendees
                    <Button className="btn btn-primary btn-sm" onClick={handleShowBulkUpload}><FaShirtsinbulk /> Bulk Action</Button>
                    <Button className="btn btn-primary btn-sm" onClick={handleShow}>+ Add</Button>
                </h1>

                {/* Modal for adding/editing attendance entries */}
                <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditing ? 'Edit Attendees Entry' : 'Add Attendees Entry'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Registration No</Form.Label>
                                <Form.Control type="text" name="registration" value={formData.registration} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formMobile">
                                <Form.Label>Mobile</Form.Label>
                                <Form.Control type="text" name="mobile" value={formData.mobile} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formDistrict">
                                <Form.Label>District</Form.Label>
                                <Form.Control type="text" name="district" value={formData.district} onChange={handleChange} required />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-3">
                                {isEditing ? 'Update' : 'Submit'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Modal for bulk upload */}
                <Modal show={showBulkUpload} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Bulk Upload Attendees</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleBulkUpload}>
                            <Form.Group controlId="formFile">
                                <Form.Label>Upload File</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange} required />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-3">Upload</Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Table to display attendance entries */}
                <div className="table-responsive mt-5">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Registration</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>District</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEntries.map((entry) => (
                                <tr key={entry.id}>
                                    <td>{entry.registration}</td>
                                    <td>{entry.name}</td>
                                    <td>{entry.email}</td>
                                    <td>{entry.mobile}</td>
                                    <td>{entry.district}</td>
                                    <td>
                                        <Button variant="warning me-3" size="sm" onClick={() => handleEdit(entry)}>Edit</Button>
                                        <Button variant="danger" size="sm" onClick={() => handleDelete(entry.id)}>Delete</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <div className="d-flex justify-content-between mt-3">
                    <Button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        Previous
                    </Button>
                    <div>
                        Page {currentPage} of {totalPages}
                    </div>
                    <Button
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Attendance;

