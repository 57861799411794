import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Header from '../Header/header';

const Notifications = () => {
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [formData, setFormData] = useState({ title: '', description: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10;

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get('https://isameapi.emedha.in/pages/api/api/notifications');
      setNotifications(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleClose = () => {
    setShow(false);
    setIsEditing(false);
    setFormData({ title: '', description: '' });
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axios.put(`https://isameapi.emedha.in/pages/api/api/notifications/${editId}`, formData);
      } else {
        await axios.post('https://isameapi.emedha.in/pages/api/api/notifications', formData);
      }
      fetchNotifications(); // Refresh the notifications list
      handleClose();
    } catch (error) {
      console.error('Error saving notification:', error);
    }
  };

  const handleEdit = (notification) => {
    setFormData(notification);
    setEditId(notification.id);
    setIsEditing(true);
    setShow(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://isameapi.emedha.in/pages/api/api/notifications/${id}`);
      fetchNotifications(); // Refresh the notifications list
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = notifications.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(notifications.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Header />
      <div className="container mt-5">
        <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <IoArrowBackCircle onClick={() => window.history.back()} style={{ cursor: 'pointer' }} />
          Notifications
          <Button className="btn btn-primary btn-sm" onClick={handleShow}>+ Add</Button>
        </h1>

        {/* Modal for adding/editing notifications */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Edit Notification' : 'Add Notification'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text" name="title" value={formData.title} onChange={handleChange} required />
              </Form.Group>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" name="description" value={formData.description} onChange={handleChange} required />
              </Form.Group>
              <Button variant="btn btn-primary btn-sm mt-3" type="submit">
                {isEditing ? 'Update' : 'Submit'}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Table to display notifications data */}
        <table className="table mt-5">
          <thead>
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((notification) => (
              <tr key={notification.id}>
                <td>{notification.title}</td>
                <td>{notification.description}</td>
                <td>
                  <Button variant="btn btn-warning btn-sm me-2" onClick={() => handleEdit(notification)}>Edit</Button>
                  <Button variant="btn btn-danger btn-sm" onClick={() => handleDelete(notification.id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between mt-3">
          <Button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>
          <div>
            Page {currentPage} of {totalPages}
          </div>
          <Button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default Notifications;
