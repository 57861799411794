import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Header from '../Header/header';
import { MdDelete } from 'react-icons/md';

const Program = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [formData, setFormData] = useState({ datetime: '', name: '', venue: '' });
    const [editIndex, setEditIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        fetchPrograms();
    }, []);

    const fetchPrograms = async () => {
        try {
            const response = await axios.get('https://isameapi.emedha.in/pages/api/api/programs');
            setPrograms(response.data);
        } catch (error) {
            console.error('Error fetching programs:', error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleShow = (index = null) => {
        if (index !== null) {
            setFormData(programs[index]);
            setEditIndex(index);
        } else {
            setFormData({ datetime: '', name: '', venue: '' });
            setEditIndex(null);
        }
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataToSubmit = { ...formData, date: formData.datetime };
        if (editIndex !== null) {
            try {
                await axios.put(`https://isameapi.emedha.in/pages/api/api/programs/${programs[editIndex].id}`, dataToSubmit);
                const updatedPrograms = programs.map((program, index) =>
                    index === editIndex ? { ...program, ...dataToSubmit } : program
                );
                setPrograms(updatedPrograms);
            } catch (error) {
                console.error('Error updating program:', error);
            }
        } else {
            try {
                const response = await axios.post('https://isameapi.emedha.in/pages/api/api/programs', dataToSubmit);
                setPrograms([...programs, response.data]);
            } catch (error) {
                console.error('Error adding program:', error);
            }
        }
        setFormData({ datetime: '', name: '', venue: '' });
        handleClose();
    };

    const handleDelete = async (index) => {
        const programToDelete = programs[index];
        try {
            await axios.delete(`https://isameapi.emedha.in/pages/api/api/programs/${programToDelete.id}`);
            const newPrograms = programs.filter((_, i) => i !== index);
            setPrograms(newPrograms);
        } catch (error) {
            console.error('Error deleting program:', error);
        }
    };

    const totalPages = Math.ceil(programs.length / itemsPerPage);
    const currentPrograms = programs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 className="d-flex justify-content-between align-items-center mb-4">
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Program
                    <Button className="btn btn-primary btn-sm" onClick={() => handleShow()}>+ Add</Button>
                </h1>

                {/* Table to display programs */}
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date & Time</th>
                                <th>Program Name</th>
                                <th>Venue</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPrograms.map((program, index) => (
                                <tr key={program.id}>
                                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>{new Date(program.datetime).toLocaleString()}</td>
                                    <td>{program.name}</td>
                                    <td>{program.venue}</td>
                                    <td>
                                        <Button variant="warning btn-sm me-2" onClick={() => handleShow((currentPage - 1) * itemsPerPage + index)}>Edit</Button>
                                        <MdDelete style={{ cursor: 'pointer' }} onClick={() => handleDelete((currentPage - 1) * itemsPerPage + index)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <nav>
                    <ul className="pagination justify-content-center">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li key={i + 1} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => setCurrentPage(i + 1)}>{i + 1}</button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                        </li>
                    </ul>
                </nav>

                {/* Modal for adding/editing program */}
                <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{editIndex !== null ? 'Edit Program' : 'Add Program'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formDateTime">
                                <Form.Label>Date & Time</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    name="datetime"
                                    value={formData.datetime}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formName">
                                <Form.Label>Program Name</Form.Label>
                                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group controlId="formVenue">
                                <Form.Label>Venue</Form.Label>
                                <Form.Control type="text" name="venue" value={formData.venue} onChange={handleChange} required />
                            </Form.Group>
                            <Button variant="primary btn-sm mt-2" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default Program;
