import React, { useState, useEffect } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Table } from 'react-bootstrap';
import Header from '../Header/header';

const ImagesUpload = () => {
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filesPerPage] = useState(10);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch('https://isameapi.emedha.in/pages/api/images');
                const data = await response.json();
                const imagesWithUrl = data.map(image => ({
                    id: image.id,  // Assuming the API returns an 'id' for each image
                    logo: image.logo,
                    type: image.fileType
                }));
                setUploadedFiles(imagesWithUrl);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileTypeChange = (event) => {
        setFileType(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileType', fileType);

            try {
                await fetch('https://isameapi.emedha.in/pages/api/upload', {
                    method: 'POST',
                    body: formData,
                });
                const imageUrl = URL.createObjectURL(file);
                setUploadedFiles([...uploadedFiles, { id: Date.now(), logo: imageUrl, type: fileType }]);
                handleModalToggle();
                setFile(null);
                setFileType('logo');
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            console.error('No file selected');
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://isameapi.emedha.in/pages/api/images/delete/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setUploadedFiles(uploadedFiles.filter(file => file.id !== id));
            } else {
                console.error('Error deleting image:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = uploadedFiles.slice(indexOfFirstFile, indexOfLastFile);
    const totalPages = Math.ceil(uploadedFiles.length / filesPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <Header />
            <div className="container mt-5">
                <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <IoArrowBackCircle onClick={handleBack} style={{ cursor: 'pointer' }} />
                    Images Sessions
                    <Button className="btn btn-primary btn-sm" onClick={handleModalToggle}>+ Add</Button>
                </h1>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Image</th>
                            <th>File Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentFiles.map((file, index) => (
                            <tr key={file.id}>
                                <td>{indexOfFirstFile + index + 1}</td>
                                <td>
                                    <img src={file.logo} alt="Uploaded" style={{ width: '60px', height: 'auto' }} />
                                </td>
                                <td>{file.type}</td>
                                <td>
                                    <Button variant="danger btn-sm" onClick={() => handleDelete(file.id)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <div className="d-flex justify-content-center pagination text-center">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={`btn-sm mx-1 ${currentPage === index + 1 ? 'btn-primary' : 'btn-secondary'}`}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </div>


                <Modal show={isModalOpen} onHide={handleModalToggle}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="fileType" className="form-label">Select File Type:</label>
                                <select id="fileType" className="form-select" value={fileType} onChange={handleFileTypeChange}>
                                    <option value="">Select Option</option>
                                    <option value="logo">Logo Image</option>
                                    <option value="directors">Directors' Images</option>
                                    <option value="trustees">Trustees Image</option>
                                </select>
                            </div>
                            <input type="file" onChange={handleFileChange} className="form-control" />
                            <Button type="submit" variant="success" className="btn-sm" style={{ marginTop: '10px' }}>Submit</Button>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default ImagesUpload;
