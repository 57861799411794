import React, { useState } from 'react';
import { BsPersonCircle, BsBoxArrowRight } from 'react-icons/bs'; // Make sure both icons are imported
import { useNavigate } from 'react-router-dom';
import A1 from '../images/isamelogo.png';

const Header = () => {
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('userData');
        sessionStorage.removeItem('userType');
        navigate('/');
        console.log('Logging out...');
        setDropdownVisible(false);
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <div style={{ backgroundColor: '#9eb7d7' }}>
            <div className="container d-flex align-items-center justify-content-between p-3">
                <div className="d-flex align-items-center">
                    <img src={A1} alt="Logo" className="logo" style={{ height: '90px' }} />
                </div>
                <h1 className="mx-auto text-center" style={{color:'#2d3a93'}}>ADMIN DASHBOARD</h1>
                <div className="position-relative">
                    <BsPersonCircle
                        size={30}
                        style={{ cursor: 'pointer' }}
                        onClick={toggleDropdown}
                    />
                    {dropdownVisible && (
                        <div style={{ position: 'absolute', right: '20px', top: '100%', backgroundColor: '#fff', zIndex: 1000, borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)' }}>
                            <button
                                onClick={handleLogout}
                                style={{ backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', padding: '10px 15px', cursor: 'pointer', transition: 'background-color 0.3s', display: 'flex', alignItems: 'center' }}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'} // Darker shade on hover
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                            >
                                <BsBoxArrowRight style={{ marginRight: '5px' }} />
                                Logout
                            </button>
                        </div>

                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;
